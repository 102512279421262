import { Router } from '../../common/router';
import {
  ROUTE_HOME,
  ROUTE_404,
  ROUTE_CATEGORY,
  ROUTE_CATEGORIES,
  ROUTE_SEARCH,
  ROUTE_LOGIN,
  ROUTE_ARCHIVE,
  ROUTE_TAGS,
  ROUTE_HASHTAG,
} from '../constants/routes';
import { getLanguage } from '../../common/store/basic-params/basic-params-selectors';
import { FEED_PAGE_NOT_FOUND } from '../../common/services/detect-route';
import { initI18N } from '../../common/services/controller-translate';
import controllerTranslations from '../../common/controller-translations.json';
import { createNotFoundPageRouter } from '../../common/controller/create-router';
import { createTagPageRouter } from './create-routers/create-tag-page-router';
import { createFeedPageRouter } from './create-routers/create-feed-page-router';
import { createCategoryPageRouter } from './create-routers/create-category-page-router';
import { createArchivePageRouter } from './create-routers/create-archive-page-router';
import { createSearchPageRouter } from './create-routers/create-search-page-router';
import { createHashtagPageRouter } from './create-routers/create-hashtag-page-router';

export const createRouter = (store, _config, wixCodeApi) => {
  initI18N(controllerTranslations, getLanguage(store.getState()));
  const router = new Router();
  router.add(ROUTE_TAGS, createTagPageRouter(store, wixCodeApi));
  router.add(ROUTE_CATEGORIES, createCategoryPageRouter(store, wixCodeApi), { preFetch: true });
  router.add(ROUTE_CATEGORY, createCategoryPageRouter(store, wixCodeApi), { preFetch: true });
  router.add(ROUTE_SEARCH, createSearchPageRouter(store, wixCodeApi));
  router.add(ROUTE_HASHTAG, createHashtagPageRouter(store, wixCodeApi));
  router.add(ROUTE_ARCHIVE, createArchivePageRouter(store, wixCodeApi));
  router.add(ROUTE_HOME, createFeedPageRouter(store, wixCodeApi), { preFetch: true });
  router.add(ROUTE_404, createNotFoundPageRouter(store, wixCodeApi, FEED_PAGE_NOT_FOUND, ROUTE_404));
  router.add(ROUTE_LOGIN);
  router.fallback(ROUTE_404);
  return router;
};
