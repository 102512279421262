import { getVideoCount, getImageCount } from '../content-selectors';
import { UPDATE_COMMENT_SUCCESS } from '../../store/comments/update-comment-types';

const handler = (action) => {
  const comment = action.payload;
  return {
    evid: 204,
    is_edited: 1,
    video_count: getVideoCount(comment.content),
    image_count: getImageCount(comment.content),
    comment_id: comment._id,
    post_id: comment.postId,
    eventMeta: {
      description: 'comment published',
    },
  };
};

export const uouUpdateCommentSuccessEvent = { [UPDATE_COMMENT_SUCCESS]: handler };
