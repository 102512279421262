import createShowMessage from '../create-show-message';

export const createMessageMiddleware = ({ resolvers }) => (store) => {
  const showMessage = createShowMessage(store);

  return (next) => (action) => {
    const resolver = resolvers[action.type];
    if (resolver) {
      showMessage(resolver(action, store.getState()), action.payload);
    }
    next(action);
  };
};
