import { createAction } from '@wix/communities-blog-client-common';

export const FETCH_CURRENT_USER_REQUEST = 'currentUser/FETCH_REQUEST';
export const FETCH_CURRENT_USER_SUCCESS = 'currentUser/FETCH_SUCCESS';
export const FETCH_CURRENT_USER_FAILURE = 'currentUser/FETCH_FAILURE';

export const fetchCurrentUserRequest = createAction(FETCH_CURRENT_USER_REQUEST);
export const fetchCurrentUserSuccess = createAction(FETCH_CURRENT_USER_SUCCESS);
export const fetchCurrentUserFailure = createAction(FETCH_CURRENT_USER_FAILURE);

export const fetchCurrentUser = (instance) => (dispatch, getState, { request }) => {
  dispatch(fetchCurrentUserRequest());
  const promise = request(`/_api/users/current`, { instance });

  return promise
    .then((currentUser) => dispatch(fetchCurrentUserSuccess(currentUser)))
    .catch(() => dispatch(fetchCurrentUserFailure()))
    .then(() => promise);
};
