import { postMapper } from '@wix/communities-blog-wix-code-common';
import urlService from './url';
import getQuery from './get-query';

const getPostBySlug = ({ instance, slug, wixCodeApi }) =>
  fetch(`${urlService.getAPIUrl(wixCodeApi)}/posts/slugs/${slug}`, {
    headers: {
      Accept: 'application/json',
      Authorization: instance,
    },
  })
    .then((resp) => (resp.ok ? resp.json() : {}))
    .then((json) => json.post)
    .then(postMapper.mapRest)
    .catch(() => undefined);

const getPostById = ({ instance, id, wixCodeApi }) =>
  fetch(`${urlService.getAPIUrl(wixCodeApi)}/posts/${id}`, {
    headers: {
      Accept: 'application/json',
      Authorization: instance,
    },
  })
    .then((resp) => (resp.ok ? resp.json() : {}))
    .then((json) => json.post)
    .then(postMapper.mapRest)
    .catch(() => undefined);

const getPosts = ({ instance, params, wixCodeApi }) =>
  fetch(`${urlService.getAPIUrl(wixCodeApi)}/posts?${getQuery(params)}`, {
    headers: {
      Accept: 'application/json',
      Authorization: instance,
    },
  })
    .then((resp) => (resp.ok ? resp.json() : {}))
    .then(({ post }) => (post ? post.map(postMapper.mapRest) : [{}]))
    .catch(() => undefined);

const getLastPost = ({ instance, wixCodeApi }) =>
  getPosts({ instance, params: { limit: 1 }, wixCodeApi }).then((posts) => posts[0]);
const getFeaturedPost = ({ instance, wixCodeApi }) =>
  getPosts({ instance, params: { limit: 1, featured: true }, wixCodeApi }).then((posts) => posts[0]);

export default {
  getPostById,
  getPostBySlug,
  getLastPost,
  getFeaturedPost,
  getPosts,
};
