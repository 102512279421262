import { createAction } from '@wix/communities-blog-client-common';
import { handleAggregatorResponse } from '../../services/aggregator';

export const FETCH_CATEGORIES_REQUEST = 'categories/FETCH_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'categories/FETCH_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'categories/FETCH_FAILURE';

export const fetchCategoriesRequest = createAction(FETCH_CATEGORIES_REQUEST);
export const fetchCategoriesSuccess = createAction(FETCH_CATEGORIES_SUCCESS);
export const fetchCategoriesFailure = createAction(FETCH_CATEGORIES_FAILURE);

const fetchCategoriesAction = () => (_dispatch, _getState, { request }) =>
  request(`/_api/categories?offset=0&size=500`);

const createFetchCategories = (action) => (...args) => (dispatch) => {
  dispatch(fetchCategoriesRequest());

  return dispatch(action(...args))
    .then((categories) => dispatch(fetchCategoriesSuccess(categories)))
    .catch(() => dispatch(fetchCategoriesFailure()));
};

const fetchCategories = createFetchCategories(fetchCategoriesAction);
export const handleCategoriesResponse = createFetchCategories(handleAggregatorResponse);

export default fetchCategories;
