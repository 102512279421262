import { ITEM_TYPES } from '@wix/advanced-seo-utils/dist/src/private/types/ItemTypes';
import {
  createPageUrl,
  getAppData,
  getCategoryUrl,
  getImageUrl,
  getSchemaEnabled,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { getPostsByCategoryIdAndPage } from '../../selectors/post-selectors';
import { getIsSitePropertiesLoaded } from '../../store/is-loaded/is-loaded-selectors';
import { fetchSiteProperties } from '../../store/site-properties/site-properties-actions';
import { getSiteProperties } from '../../store/site-properties/site-properties-selectors';
import { getSectionUrl, getPostPageSectionUrl } from '../../store/topology/topology-selectors';
import { getCategorySchema } from '../json-schema';
import { getPaginationItemData } from './get-pagination-item-data';
import { getCategoriesMap } from '../../selectors/categories-selectors';
import { EXPERIMENT_READABLE_SEO_URL } from '@wix/communities-blog-experiments';

export const generateCategorySEOTags = async ({ appConfig, sectionUrl, category, store, page, showPagination, t }) => {
  let state = store.getState();
  const isReadableSEOURLEnabled = isExperimentEnabled(state, EXPERIMENT_READABLE_SEO_URL);

  const categoryUrl = getCategoryUrl(sectionUrl, appConfig.categoryPath, category.slug, isReadableSEOURLEnabled);
  const categoryUrlWithPage = createPageUrl(page, categoryUrl || getSectionUrl(state), {
    readable: isReadableSEOURLEnabled,
  });

  const itemData = {
    title: appConfig.useCategoryMenuLabelForMetadataTitle ? category.menuLabel : category.label,
    label: category.menuLabel,
    description: category.description,
    canonicalUrl: categoryUrlWithPage,
  };

  if (appConfig.isWP || getSchemaEnabled(state)) {
    if (!getIsSitePropertiesLoaded(state)) {
      await store.dispatch(fetchSiteProperties());
    }

    state = store.getState();
    itemData.structuredData = JSON.stringify(
      getCategorySchema({
        posts: getPostsByCategoryIdAndPage(state, category._id, page),
        category,
        categoryMap: getCategoriesMap(state),
        appConfig,
        appData: getAppData(state),
        siteProperties: getSiteProperties(state),
        sectionUrl,
        postPageSectionUrl: getPostPageSectionUrl(state),
        isReadableSEOURLEnabled,
      }),
    );
  }

  const itemDataWithPagination = getPaginationItemData({
    itemData,
    state,
    url: categoryUrl,
    page,
    t,
    updateTitle: showPagination,
  });

  if (category.cover) {
    const { width, height } = category.cover;
    itemDataWithPagination.image = {
      url: getImageUrl({ imageHost: appConfig.imageHost, image: category.cover }),
      width,
      height,
    };
  }

  return {
    itemType: ITEM_TYPES.BLOG_CATEGORY,
    itemData: { category: itemDataWithPagination },
  };
};
