import { addErrorState } from './debug-state-actions';
import { normalizeError } from '../../services/create-monitoring';

const formatError = (error) => error.stack || `${error}`;

export function handleError({ controller, store, setProps, appParams, captureToSentry }) {
  return (error) => {
    console.error(`--- error in ${controller} controller ---`);
    console.error(error);

    const shouldCaptureToSentry = typeof captureToSentry === 'function';
    let cssBaseUrl;

    try {
      cssBaseUrl = appParams.baseUrls.staticsBaseUrl;
      let workerErrorId;

      if (shouldCaptureToSentry) {
        workerErrorId = captureToSentry(normalizeError(error));
      }

      setProps({
        state: store ? store.getState() : undefined,
        cssBaseUrl,
        workerError: formatError(error),
        workerErrorId,
        wasWorkerErrorCaptured: shouldCaptureToSentry,
      });

      store && store.dispatch(addErrorState(error));
    } catch (ex) {
      console.error(ex);
      setProps({
        cssBaseUrl,
        workerError: formatError(ex),
      });
    }
  };
}
