import { categoryIds } from '../event-helpers';
import { getPost } from '../../selectors/post-selectors';
import { DELETE_COMMENT_SUCCESS } from '../../store/comments/delete-comment-types';

const handler = ({ payload: { postId, _id } }, state) => {
  const post = getPost(state, postId);
  return {
    evid: 234,
    post_id: postId,
    post_stable_id: post.id,
    category_ids: categoryIds(post),
    comments_id: _id,
  };
};

export const uouDeleteCommentEvent = {
  [DELETE_COMMENT_SUCCESS]: handler,
};
