const createResponseHandler = ({ withHeaders = false } = {}) => (response) => () => {
  if (response.status < 300) {
    return withHeaders
      ? Promise.resolve({ body: response.body, headers: new Headers(response.headers) })
      : Promise.resolve(response.body);
  }

  return Promise.reject(response);
};

export const handleAggregatorResponse = createResponseHandler({ withHeaders: false });
export const handleAggregatorResponseWithHeaders = createResponseHandler({ withHeaders: true });
