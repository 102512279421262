import { createAction } from '@wix/communities-blog-client-common';
import { DELETE_COMMENT_FAILURE, DELETE_COMMENT_REQUEST, DELETE_COMMENT_SUCCESS } from './delete-comment-types';

export const deleteCommentRequest = createAction(DELETE_COMMENT_REQUEST);
export const deleteCommentSuccess = createAction(DELETE_COMMENT_SUCCESS);
export const deleteCommentFailure = createAction(DELETE_COMMENT_FAILURE);

export function deleteComment({ postId, commentId }) {
  return (dispatch, getState, { request }) => {
    dispatch(deleteCommentRequest());

    const promise = request.delete(`/_api/posts/${postId}/comments/${commentId}`);

    return promise
      .then(() => dispatch(deleteCommentSuccess({ _id: commentId, postId })))
      .catch(() => dispatch(deleteCommentFailure()))
      .then(() => promise);
  };
}
