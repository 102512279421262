import { START_EDITING_COMMENT } from './start-editing-comment';
import { STOP_EDITING_COMMENT } from './stop-editing-comment';
import { UPDATE_COMMENT_SUCCESS } from './update-comment-types';

export default function commentEdit(state = null, action) {
  switch (action.type) {
    case START_EDITING_COMMENT:
      return action.payload.comment._id;
    case STOP_EDITING_COMMENT:
    case UPDATE_COMMENT_SUCCESS:
      return null;
    default:
      return state;
  }
}
