import { once, noop } from 'lodash';
import { createNoopController } from '../../viewer-script/controllers/createNoopController';
import { createControllerId, createLogger } from '../../common/controller/helpers';
import getEnvironment from '../../common/services/get-environment';
import { createReduxStore } from './create-redux-store';
import { handleProvisioning } from '../../common/services/handle-provisioning';
import { handleError } from '../../common/store/debug-state/handle-error';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';
import { POST_LIST_WIDGET_NAME } from '../../viewer-script/constants/widgets';
import { createMonitoring } from '../../common/services/create-monitoring';
import { createPerformanceTracker } from '../../common/services/performance-tracker';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { simulateControllerError } from '../../common/services/simulate-error';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToEditModeChange from '../../common/services/listen-to-edit-mode-change';
import listenToSettingsChange from '../../common/services/listen-to-settings-change';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { POST_LIST_WIDGET_BUNDLE_NAME } from '../constants/post-list-widget';
import { fetchPostListPostsPromisified, fetchPostListPosts } from '../actions/fetch-post-list-posts';
import Wix from '../../common/services/wix-sdk-polyfill';
import { setWixDataSuccess } from '@wix/communities-blog-client-common';
import { initLazyActions } from '../../common/controller/lazy-actions';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';

const isProduction = process.env.NODE_ENV === 'production';
let isControllerInitialized = false;

export const createPostListController = (
  { appParams, compId, config, setProps, wixCodeApi, platformAPIs, type },
  allCtrls,
  context,
) => {
  const { isEditor, isPreview, isSSR, isDebug } = getEnvironment(wixCodeApi);

  // there is a bug in viewerScriptWrapper where it initialized controller again when going into preview
  if ((isEditor || isPreview) && isControllerInitialized) {
    return createNoopController();
  } else {
    isControllerInitialized = true;
  }

  const { captureToSentry, fedopsLogger } = createMonitoring({
    origin: `app-worker-${POST_LIST_WIDGET_NAME}`,
    platformAPIs,
    appParams,
    wixCodeApi,
    type,
  });

  fedopsLogger.appLoadStarted();
  const fedopsAppLoaded = once(() => fedopsLogger.appLoaded());

  const language = getCurrentSiteLanguage(wixCodeApi);

  const bundleName = POST_LIST_WIDGET_BUNDLE_NAME;

  const log = createLogger(isDebug, isProduction);

  log('createPostListController', { appParams, allCtrls, wixCodeApi, isSSR, language });

  const pageReady = async () => {
    let store;
    try {
      const controllerId = createControllerId();
      const perfTag = `${bundleName} ${controllerId}`;
      const perf = createPerformanceTracker(perfTag, { isDebug, isSSR });
      const pageReadyMarker = perf.trackStart(`${new Date().toISOString().slice(11)} pageReady`);
      log('createPostListController.pageReady -> start');

      simulateControllerError(wixCodeApi, 'post-list.pageReady');

      initLazyActions({ staticsBaseUrl: appParams.baseUrls.staticsBaseUrl, isPreview, isEditor, isSSR });

      let marker = perf.trackStart('createReduxStore', pageReadyMarker);
      store = createReduxStore({
        appParams,
        wixCodeApi,
        compId,
        fedopsLogger,
        isSSR,
        isEditor,
        isPreview,
        language,
        bundleName: perfTag,
      });
      perf.trackEnd(marker);
      marker = perf.trackStart('initializeActions', pageReadyMarker);
      const actions = initializeActions({
        wixCodeApi,
        store,
        fedopsLogger,
        fedopsAppLoaded,
      });
      const actionsPromisified = initializePromisifiedActions(
        { wixCodeApi, compId, store },
        { fetchPostListPostsPromisified },
      );
      perf.trackEnd(marker);

      await perf.trackPromise(
        'initializeStoreBaseData',
        () =>
          initializeStoreBaseData({
            wixCodeApi,
            store,
            language,
            platformAPIs,
            config,
            context,
            bundleName,
            fedopsAppName: 'communities-blog-post-list-widget',
            translationsName: 'post-list-widget',
          }),
        pageReadyMarker,
      );

      log('createPostListController.pageReady -> done');
      const state = store.getState();

      const stateVersions = getInitialStateVersions(state);
      setProps({
        state,
        stateVersions,
        actions,
        actionsPromisified,
        cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
        isSSR,
        isRTL: isRtlLanguage(language),
        controllerId,
      });

      if (isSSR) {
        fedopsAppLoaded();
      }

      if (isEditor || isPreview) {
        listenToEditModeChange(store);
        listenToSettingsChange(store);

        Wix.addEventListener(Wix.Events.PUBLIC_DATA_CHANGED, (data) => {
          if (data && typeof data.categoryId !== undefined) {
            store.dispatch(setWixDataSuccess(data));
            store.dispatch(fetchPostListPosts());
          }
        });
      }

      refreshDataOnLogin({ wixCodeApi, store, router: undefined, getPathname: () => undefined, doRouting: noop });
      subscribeToChange(store, stateVersions, setProps, controllerId);
      perf.trackEnd(pageReadyMarker);
    } catch (error) {
      handleError({
        controller: POST_LIST_WIDGET_NAME,
        store,
        setProps,
        appParams,
        captureToSentry,
        isDebug,
        isSSR,
      })(error);
    }
  };

  return Promise.resolve({
    pageReady: () =>
      isEditor ? handleProvisioning(appParams, fedopsLogger, wixCodeApi, setProps, pageReady) : pageReady(),
    exports: () => {},
  }).catch(console.error);
};
