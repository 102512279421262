import { createAction } from '@wix/communities-blog-client-common';
import { getIsDemoMode } from '../instance-values/instance-values-selectors';

export const SET_APP_SETTINGS = 'appSettings/SET_APP_SETTINGS';
export const setAppSettingsAction = createAction(SET_APP_SETTINGS);

export const setAppSettings = (payload) => (dispatch) => {
  dispatch(setAppSettingsAction(payload));
  return Promise.resolve();
};

export const UPDATE_APP_SETTINGS_REQUEST = 'appSettings/UPDATE_REQUEST';
export const UPDATE_APP_SETTINGS_SUCCESS = 'appSettings/UPDATE_SUCCESS';
export const UPDATE_APP_SETTINGS_FAILURE = 'appSettings/UPDATE_FAILURE';
export const UPDATE_APP_SETTINGS_ON_STORE = 'appSettings/UPDATE_ON_STORE';

export const updateAppSettingsRequest = (payload, externalId) => ({
  type: UPDATE_APP_SETTINGS_REQUEST,
  payload,
  meta: {
    bi: {
      externalId,
    },
  },
});
export const updateAppSettingsSuccess = (payload) => ({
  type: UPDATE_APP_SETTINGS_SUCCESS,
  payload,
});
export const updateAppSettingsFailure = () => ({
  type: UPDATE_APP_SETTINGS_FAILURE,
});

export const updateAppSettings = (compId, externalId, viewMode, settings, isMainPage = false) => (
  dispatch,
  getState,
  { request },
) => {
  const isDemoMode = getIsDemoMode(getState());
  if (isDemoMode) {
    return Promise.resolve();
  }

  dispatch(updateAppSettingsRequest(settings, externalId));

  return request
    .post(
      `/_api/settings?compId=${compId}&externalId=${externalId}&viewMode=${viewMode}&isMainPage=${isMainPage}`,
      settings,
    )
    .then((responseData) => dispatch(updateAppSettingsSuccess(responseData)))
    .catch(() => dispatch(updateAppSettingsFailure()));
};

export const updateAppSettingsOnStore = (payload) => ({
  type: UPDATE_APP_SETTINGS_ON_STORE,
  payload,
});
