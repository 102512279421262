import { CLOSE_MODAL } from './modal-actions';
// import { getModalMeta } from './modal-selectors';

export const createModalClosedMiddleware = ({ createShowMessage, modalResolverMapByType }) => (store) => {
  const showMessage = createShowMessage(store);

  return (next) => (action) => {
    if (action.type === CLOSE_MODAL) {
      const {
        payload: { type, resolve },
      } = action;

      // @todo: s.dubinskas - fix focusOnCloseId (move outside the worker)
      // const { focusOnCloseId } = getModalMeta(store.getState(), type);
      // if (focusOnCloseId) {
      //   const element = document.getElementById(focusOnCloseId);
      //   // without timeout closing modal with Enter will fire key up on focused element
      //   element && setTimeout(() => element.focus(), 100);
      // }

      const resolver = modalResolverMapByType[type];
      if (resolver && !(resolve === false || resolve === undefined)) {
        store.dispatch(
          resolver(resolve, {
            showMessage,
          }),
        );
      }
    }

    next(action);
  };
};
