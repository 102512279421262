import { compact } from 'lodash';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createRequests from '../../common/services/create-requests';
import commonReducers from '../../common/reducers';
import blogStatsReducer from '../../common/store/blog-stats/blog-stats-reducer';
import { appUouBiMiddleware } from '../../common/bi-events/init-middleware';
import { eventMap } from '../bi-events/uou-events';

export function createReduxStore({ appParams, wixCodeApi, compId, fedopsLogger, isEditor, platformAPIs, bundleName }) {
  const p = {};

  const { request, platformizedRequest } = createRequests({
    appParams,
    wixCodeApi,
    bundleName,
    getStore: () => p.store,
  });

  const biMiddleware = !isEditor && appUouBiMiddleware({ platformAPIs, instanceId: appParams.instanceId, eventMap });

  const middleware = compact([
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
    }),
    biMiddleware,
  ]);

  const reducers = combineReducers({ ...commonReducers, blogStats: blogStatsReducer });

  p.store = createStore(reducers, undefined, applyMiddleware(...middleware));

  return p.store;
}
