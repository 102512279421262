import { SET_USER } from './set-user';
import { LOGOUT_MEMBER_SUCCESS } from './logout-member';

export default (state = false, { type }) => {
  switch (type) {
    case SET_USER:
      return true;
    case LOGOUT_MEMBER_SUCCESS:
      return false;
    default:
      return state;
  }
};
