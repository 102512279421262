import { chain } from 'lodash';
import Wix from './wix-sdk-polyfill';
import { updateAppSettings, updateAppSettingsOnStore } from '../store/app-settings/app-settings-actions';

export default function listenToSettingsChange(store) {
  Wix.addEventListener(Wix.Events.SETTINGS_UPDATED, (data) => store.dispatch(data));

  Wix.addEventListener(Wix.Events.STYLE_PARAMS_CHANGE, (style) => {
    const styleSettings = getStyleSettings(style);

    store.dispatch(updateAppSettingsOnStore(styleSettings));
  });

  // TODO: this is a temporary solution to make app-settings available in the business-manager
  // while the wix-site-query-web does not support DRAFT revision.
  Wix.addEventListener(Wix.Events.SITE_SAVED, () => {
    Wix.Styles.getStyleParams((style) => {
      store.dispatch(updateAppSettings(Wix.Utils.getCompId(), 'draft', 'editor', getStyleSettings(style)));
    });
  });
}

const EXCLUDED_CSS_FONTS = /fonts\.googleapis/;

function getStyleSettings(style = {}) {
  return {
    style: {
      ...style,
      cssUrls: getFontCssUrls(),
    },
    textPresets: Wix.Styles.getSiteTextPresets() || {},
    colorPresets: Wix.Styles.getSiteColors() || [],
  };
}

function getFontCssUrls() {
  return chain(document.styleSheets)
    .filter((styleSheet) => styleSheet.href)
    .map((styleSheet) => styleSheet.href.toLowerCase())
    .map((href) => href.replace(/^https?:/, ''))
    .filter((href) => !EXCLUDED_CSS_FONTS.test(href))
    .filter((href) => href.indexOf('font') >= 0)
    .uniq()
    .value();
}
