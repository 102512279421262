const LOCALIZATION_API_BUNDLE_NAME = 'localization-api';

let localizationApi;

export const getLocalizationApi = async ({
  isSSR,
  isPreview,
  isEditor,
  staticsBaseUrl,
  isProdOOIEditorExperimentEnabled,
} = {}) => {
  if (localizationApi || isSSR) {
    return localizationApi;
  }

  if ((isProdOOIEditorExperimentEnabled && isPreview) || isEditor) {
    localizationApi = (
      await import(/* webpackChunkName: "localization-api-editor" */ '../../viewer-script/localization-api')
    ).default;
  } else {
    importScripts(staticsBaseUrl + `./${LOCALIZATION_API_BUNDLE_NAME}.bundle.min.js`);
    localizationApi = global[LOCALIZATION_API_BUNDLE_NAME].default;
  }

  return localizationApi;
};
