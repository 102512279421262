import { getTagListItems } from '../get-tag-list-items';
import { getTagUrl } from '../get-tag-url';
import { getPaginationItemData } from './get-pagination-item-data';

export const generateTagSEOTags = ({ sectionUrl, postPageSectionUrl, tag, state, page, posts, showPagination, t }) => {
  const tagUrl = getTagUrl(sectionUrl, tag.slug);

  const itemData = {
    name: tag.label,
    fullUrl: tagUrl,
    listItems: getTagListItems(posts, postPageSectionUrl),
    created: tag.createdDate,
    postCount: tag.postCount,
  };

  const itemDataWithPagination = getPaginationItemData({
    itemData,
    state,
    url: tagUrl,
    page,
    t,
    updateTitle: showPagination,
  });

  return {
    itemType: 'BLOG_TAGS', // constant is not provided by advanced-seo-utils
    itemData: { tag: itemDataWithPagination },
  };
};
