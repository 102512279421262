import { head, flowRight } from 'lodash';

import { POST_STATUS } from '@wix/communities-blog-universal/dist/src/constants/post';
import { createAction, ENTITY_TYPE_POSTS, urijs } from '@wix/communities-blog-client-common';
import { buildPaginationRequestParams } from '../services/pagination';
import { getCategoryIds } from '../selectors/categories-selectors';
import { normalizePosts } from '../services/post-utils';
import { getIsFeaturedPostBannerEnabled } from '../selectors/app-settings-selectors';

export const FETCH_LAST_FEATURED_POST_REQUEST = 'lastFeaturedPost/FETCH_REQUEST';
export const FETCH_LAST_FEATURED_POST_SUCCESS = 'lastFeaturedPost/FETCH_SUCCESS';
export const FETCH_LAST_FEATURED_POST_FAILURE = 'lastFeaturedPost/FETCH_FAILURE';

export const fetchLastFeaturedPostRequest = createAction(FETCH_LAST_FEATURED_POST_REQUEST);
export const fetchLastFeaturedPostSuccess = createAction(FETCH_LAST_FEATURED_POST_SUCCESS, (payload) => payload);
export const fetchLastFeaturedPostFailure = createAction(FETCH_LAST_FEATURED_POST_FAILURE);

export const fetchLastFeaturedPost = (preFetch) => (dispatch, getState, { request }) => {
  dispatch(fetchLastFeaturedPostRequest());

  const paginationParams = buildPaginationRequestParams(1, 1);
  const query = {
    ...paginationParams,
    entityType: ENTITY_TYPE_POSTS,
    status: POST_STATUS.published,
    featuredOnly: true,
  };
  const promise = request(urijs('/_api/posts').query(query), { parseHeaders: true });

  return preFetch ? promise : completeFetchLastFeaturedPost(promise)(dispatch, getState);
};

export const completeFetchLastFeaturedPost = (promise) => async (dispatch, getState) => {
  try {
    const { body = [] } = await promise;
    flowRight(
      dispatch,
      fetchLastFeaturedPostSuccess,
      head,
      normalizePosts,
    )({ posts: body, blogCategoryIds: getCategoryIds(getState()) });
  } catch (_) {
    dispatch(fetchLastFeaturedPostFailure());
  }
  return promise;
};

export const fetchLastFeaturedPostIfNeeded = () => (dispatch, getState, args) =>
  getIsFeaturedPostBannerEnabled(getState()) ? fetchLastFeaturedPost()(dispatch, getState, args) : Promise.resolve();
